import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { form, inputs, privacyInfo, button } from './file-form.module.scss';
import { postFileForm } from '../../api/file-form';
import { IFormHandleMethods } from '../../templates/form-page';

import FormikForm from '../hoc/formik-form';
import Input from '../atoms/form/input';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';
import Checkbox from '../atoms/form/checkbox';

export interface IFileFormProps extends IFormHandleMethods<IFileFormValues> {
    className?: string;
    formId: number;
}

export interface IFileFormValues {
    name: string;
    surname: string;
    email: string;
    consent: boolean;
}

const initialValues: IFileFormValues = {
    name: '',
    surname: '',
    email: '',
    consent: false,
};

const FileForm: React.FC<IFileFormProps> = ({
    className = '',
    formId,
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
}) => {
    const { t } = useI18next();

    const handleSubmit = (values: FormikValues, helpers: FormikHelpers<IFileFormValues>) => {
        onLoading(helpers);

        postFileForm({
            name: values.name,
            surname: values.surname,
            email: values.email,
            formId: formId,
        })
            .then((res) => {
                console.log(res.data);
                const response = {
                    formId: formId,
                    applicationId: res.data.applicationId,
                    name: values.name,
                    level: res.data.level,
                    items: res.data.items,
                };

                onSuccess(helpers, response);
            })
            .catch(() => {
                onError(helpers);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
        >
            {(formik) => {
                return (
                    <FormikForm className={`${form} ${className}`} formik={formik}>
                        <div className={inputs}>
                            <Input
                                name="name"
                                as="text"
                                placeholder={t('form.file.placeholder.name')}
                            />
                            <Input
                                name="surname"
                                as="text"
                                placeholder={t('form.file.placeholder.surname')}
                            />
                            <Input
                                name="email"
                                type="email"
                                placeholder={t('form.file.placeholder.email')}
                            />
                            <Checkbox name={'consent'} text={t('form.consent')} />
                        </div>

                        <Button
                            text={t('form.file.button.send')}
                            className={button}
                            type={'submit'}
                        />
                        <Markdown className={privacyInfo}>{t('form.privacyInfo')}</Markdown>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

function getValidationSchema(t: ReturnType<typeof useI18next>['t']) {
    return Yup.object({
        name: Yup.string().required(t('form.consultation.error.name.required')),
        surname: Yup.string().required(t('form.consultation.error.surname.required')),
        email: Yup.string()
            .email(t('form.error.email.format'))
            .required(t('form.error.email.required')),
        consent: Yup.boolean().oneOf([true], t('form.error.consent.required')),
    });
}

export default FileForm;

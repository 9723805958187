import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { form, inputs, button } from './file-position-data-form.module.scss';
import { postFileForm } from '../../api/file-form';
import { IFormHandleMethods } from '../../templates/form-page';

import FormikForm from '../hoc/formik-form';
import Input from '../atoms/form/input';
import Button from '../atoms/button';

export interface IFilePositionDataFormProps
    extends IFormHandleMethods<IFilePositionDataFormValues> {
    className?: string;
    formId: number;
    applicationId: number;
}

export interface IFilePositionDataFormValues {
    companyName: string;
    position: string;
}

const initialValues: IFilePositionDataFormValues = {
    companyName: '',
    position: '',
};

const FilePositionDataForm: React.FC<IFilePositionDataFormProps> = ({
    className = '',
    formId,
    applicationId,
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
}) => {
    const { t } = useI18next();

    const handleSubmit = (
        values: FormikValues,
        helpers: FormikHelpers<IFilePositionDataFormValues>
    ) => {
        onLoading(helpers);

        postFileForm({
            formId: formId,
            applicationId: applicationId,
            name: values.name,
            surname: values.surname,
            email: values.email,
            rangeId: values.rangeId,
            industryId: values.industryId,
            companyName: values.companyName,
            position: values.position,
        })
            .then((response) => {
                onSuccess(helpers, response);
            })
            .catch(() => {
                onError(helpers);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
        >
            {(formik) => {
                return (
                    <FormikForm className={`${form} ${className}`} formik={formik}>
                        <div className={inputs}>
                            <Input
                                name="companyName"
                                as="text"
                                placeholder={t('form.filePositionData.placeholder.companyName')}
                            />
                            <Input
                                name="position"
                                as="text"
                                placeholder={t('form.filePositionData.placeholder.position')}
                            />
                        </div>

                        <Button
                            text={t('form.filePositionData.button.send')}
                            className={button}
                            type={'submit'}
                        />
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

function getValidationSchema(t: ReturnType<typeof useI18next>['t']) {
    return Yup.object({
        companyName: Yup.string().required(t('form.filePositionData.error.companyName.required')),
        position: Yup.string().required(t('form.filePositionData.error.position.required')),
    });
}

export default FilePositionDataForm;

import axios from 'axios';
import endpoints from '../config/endpoints';

const API_URL = process.env.API_URL;

interface INewsletterData {
    name: string;
    surname: string;
    email: string;
}

export function postNewsletter(data: INewsletterData): Promise<any> {
    return axios.post(endpoints({ API_URL }).newsletterSubscribe, data, {
        withCredentials: true,
    });
}

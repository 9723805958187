import React from 'react';
import { graphql, navigate } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';
import { FormikHelpers } from 'formik';

import { layout, title as titleClass, subtitle, image, ratio } from './form-page.module.scss';
import { statusMap } from '../config/status-map';
import { translationKeys } from '../config/translation-keys';
import { relations } from '../config/relations';
import { IFileForm } from '../models/file-form.model';
import { IIndustry } from '../models/industry.model';
import { IRange } from '../models/range.model';
import { INewsletter } from '../models/newsletter.model';
import { useModalContext } from '../contexts/modal-context';
import { useTranslation } from '../hooks/use-translation';
import useMedia from '../hooks/use-media';

import ContentLayout from '../layouts/content-layout';
import FileForm from '../components/organisms/file-form';
import FilePositionDataForm from '../components/organisms/file-position-data-form';
import FileCompanyDataForm from '../components/organisms/file-company-data-form';
import NewsletterForm from '../components/organisms/newsletter-form';
import RatioImage from '../components/atoms/ratio-image';

enum EUserLevel {
    UserDoesntExistInDatabase = 0,
    UserExistsInDatabase = 1,
    UserAndCompanyDataExistInDatabase = 2,
    UserAndPositionDataExistInDatabase = 3,
}

interface IFormResponse {
    formId: number;
    name: string;
    level: EUserLevel;
    items: [];
}

export interface IFormHandleMethods<T> {
    onSuccess?(helpers: FormikHelpers<T>, props?: IFormResponse): void;
    onError?(helpers: FormikHelpers<T>): void;
    onLoading?(helpers: FormikHelpers<T>): void;
}

interface IFormPageProps {
    readonly data: {
        fileForm: IFileForm;
        newsletter: INewsletter;
    };
    readonly location: {
        state: {
            formId: number;
            applicationId: number;
            name: string;
            items: {
                industries?: IIndustry[];
                ranges?: IRange[];
            };
        };
    };
    readonly pageContext: {
        formType: string;
    };
}

const FormPage: React.FC<IFormPageProps> = ({ data, location, pageContext }) => {
    if (typeof window === 'undefined') return null;

    const { t } = useI18next();
    const { addModal } = useModalContext();
    const { name, applicationId, items } = location.state || {};
    const { fileForm } = data || {};
    const { title, lead } = useTranslation(fileForm, translationKeys.fileForm);
    const { label, subLabel } = useTranslation(pageContext, translationKeys.pageContext);
    const { formType } = pageContext;
    const { name: newsletterName, description } = data.newsletter || {};
    const img = useMedia({
        media: fileForm?.media,
        relation: relations.mainImage,
    });

    let titleToDisplay;
    let subtitleToDisplay;

    switch (formType) {
        case 'newsletter':
            titleToDisplay = newsletterName;
            subtitleToDisplay = description;
            break;

        case 'file':
            titleToDisplay = title;
            subtitleToDisplay = lead;
            break;

        case 'file-company-data':
        case 'file-position-data':
            titleToDisplay = `${label} ${name || ''}`;

            if (!location) navigate('/');
            break;

        default:
            titleToDisplay = label;
            titleToDisplay = subLabel;
    }

    const handleLoading = <FormikValues,>(helpers: FormikHelpers<FormikValues>) => {
        helpers.setStatus(statusMap.loading);
        addModal({
            type: 'loading',
        });
    };

    const handleSuccess = <FormikValues,>(
        helpers: FormikHelpers<FormikValues>,
        response: IFormResponse
    ) => {
        helpers.setStatus(statusMap.idle);

        if (formType === 'newsletter') {
            return navigate('/newsletter/podziekowanie/');
        }

        if (formType === 'file-company-data' || formType === 'file-position-data') {
            return navigate('/darmowy-dokument/sprawdz-email/');
        }

        switch (response?.level) {
            case EUserLevel.UserExistsInDatabase:
                return navigate('/darmowy-dokument/dane-firmy/', {
                    state: response,
                });

            case EUserLevel.UserAndCompanyDataExistInDatabase:
                return navigate('/darmowy-dokument/dane-stanowiska/', {
                    state: response,
                });

            case EUserLevel.UserDoesntExistInDatabase:
            case EUserLevel.UserAndPositionDataExistInDatabase:
                return navigate('/darmowy-dokument/sprawdz-email/');
        }
    };

    const handleError = <FormikValues,>(helpers: FormikHelpers<FormikValues>) => {
        helpers.setStatus(statusMap.error);
        addModal({
            type: 'error',
            message: t('form.contact.error'),
        });
    };

    return (
        <ContentLayout SEOProps={{ title }} className={layout}>
            {img.image && formType === 'file' && (
                <RatioImage image={img.image} alt={img.alt} className={image} ratioClass={ratio} />
            )}

            {!img.image && formType === 'file' && (
                <StaticImage
                    className={image}
                    src="../assets/images/file.jpg"
                    alt={''}
                    quality={95}
                />
            )}

            {formType === 'file-company-data' && (
                <StaticImage
                    className={image}
                    src="../assets/images/file.jpg"
                    alt={''}
                    quality={95}
                />
            )}

            {formType === 'file-position-data' && (
                <StaticImage
                    className={image}
                    src="../assets/images/file.jpg"
                    alt={''}
                    quality={95}
                />
            )}

            {formType === 'newsletter' && (
                <StaticImage
                    className={image}
                    src="../assets/images/newsletter.jpg"
                    alt={''}
                    quality={95}
                />
            )}

            {formType === '' && (
                <StaticImage
                    className={image}
                    src="../assets/images/email.jpg"
                    alt={''}
                    quality={95}
                />
            )}

            <div>
                {titleToDisplay && <h1 className={titleClass}>{titleToDisplay}</h1>}
                {subtitleToDisplay &&
                    formType !== 'file-company-data' &&
                    formType !== 'file-position-data' && (
                        <h2 className={subtitle}>{subtitleToDisplay}</h2>
                    )}

                {formType === 'file' && (
                    <FileForm
                        formId={fileForm?.articleId}
                        onLoading={handleLoading}
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />
                )}

                {formType === 'file-company-data' && (
                    <FileCompanyDataForm
                        formId={location?.state?.formId}
                        applicationId={applicationId}
                        industries={items?.industries || []}
                        ranges={items?.ranges || []}
                        onLoading={handleLoading}
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />
                )}

                {formType === 'file-position-data' && (
                    <FilePositionDataForm
                        formId={location?.state?.formId}
                        applicationId={applicationId}
                        onLoading={handleLoading}
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />
                )}

                {formType === 'newsletter' && (
                    <NewsletterForm
                        onLoading={handleLoading}
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />
                )}
            </div>
        </ContentLayout>
    );
};

export const query = graphql`
    query ($language: String!, $articleId: Int) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        fileForm(articleId: { eq: $articleId }) {
            ...fileFormFields
        }
        newsletter(newsletterId: { eq: 1 }) {
            newsletterId
            name
            description
        }
    }
`;

export default FormPage;

import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, form, inputs } from './file-company-data-form.module.scss';
import { postFileForm } from '../../api/file-form';
import { useTranslation } from '../../hooks/use-translation';
import { translationKeys } from '../../config/translation-keys';
import { IIndustry } from '../../models/industry.model';
import { IRange } from '../../models/range.model';
import { IFormHandleMethods } from '../../templates/form-page';

import FormikForm from '../hoc/formik-form';
import Button from '../atoms/button';
import InputSelect from '../atoms/input-select';
import { navigate } from 'gatsby';

export interface IFileCompanyDataFormProps extends IFormHandleMethods<IFileCompanyDataFormValues> {
    className?: string;
    formId: number;
    applicationId: number;
    industries: IIndustry[];
    ranges: IRange[];
}

export interface IFileCompanyDataFormValues {
    industryId: number;
    rangeId: number;
}

const initialValues: IFileCompanyDataFormValues = {
    industryId: NaN,
    rangeId: NaN,
};

const FileCompanyDataForm: React.FC<IFileCompanyDataFormProps> = ({
    className = '',
    formId,
    applicationId,
    industries,
    ranges,
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
}) => {
    const { t } = useI18next();
    const industriesOptions = getIndustriesOptions(industries);
    const rangeOptions = getRangeOptions(ranges);

    if (industries.length === 0 || ranges.length === 0) {
        navigate('/darmowy-dokument/sprawdz-email/');
    }

    const handleSubmit = (
        values: FormikValues,
        helpers: FormikHelpers<IFileCompanyDataFormValues>
    ) => {
        onLoading(helpers);

        postFileForm({
            formId: formId,
            applicationId: applicationId,
            name: values.name,
            surname: values.surname,
            email: values.email,
            rangeId: values.rangeId,
            industryId: values.industryId,
            companyName: values.companyName,
            position: values.position,
        })
            .then((res) => {
                const response = {
                    formId: formId,
                    applicationId: applicationId,
                    name: values.name,
                    level: res.data.level,
                    items: res.data.items,
                };

                onSuccess(helpers, response);
            })
            .catch(() => {
                onError(helpers);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
        >
            {(formik) => {
                return (
                    <FormikForm className={`${form} ${className}`} formik={formik}>
                        <div className={inputs}>
                            {industriesOptions.length > 0 && (
                                <InputSelect
                                    name={'industryId'}
                                    placeholder={t('form.fileSecondStep.placeholder.industryId')}
                                    options={industriesOptions}
                                />
                            )}

                            {rangeOptions.length > 0 && (
                                <InputSelect
                                    name={'rangeId'}
                                    placeholder={t('form.fileSecondStep.placeholder.rangeId')}
                                    options={rangeOptions}
                                />
                            )}
                        </div>

                        <Button
                            text={t('form.fileSecondStep.button.send')}
                            className={button}
                            type={'submit'}
                        />
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

function getIndustriesOptions(industries: IIndustry[]) {
    return industries.map((industry) => {
        const { name } = useTranslation(industry, translationKeys.industry);

        return {
            value: industry.industryId,
            label: name,
        };
    });
}

function getRangeOptions(ranges: IRange[]) {
    return ranges.map((range) => {
        const { name } = useTranslation(range, translationKeys.range);

        return {
            value: range.rangeId,
            label: name,
        };
    });
}

function getValidationSchema(t: ReturnType<typeof useI18next>['t']) {
    return Yup.object({
        industryId: Yup.number()
            .required(t('form.fileSecondStep.error.industryId.required'))
            .typeError(t('form.fileSecondStep.error.industryId.required')),
        rangeId: Yup.number()
            .required(t('form.fileSecondStep.error.rangeId.required'))
            .typeError(t('form.fileSecondStep.error.rangeId.required')),
    });
}

export default FileCompanyDataForm;

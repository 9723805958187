import axios from 'axios';
import endpoints from '../config/endpoints';

const API_URL = process.env.API_URL;

interface IFileFormData {
    name: string;
    surname: string;
    email: string;
    formId: number;
    applicationId?: number;
    rangeId?: number;
    industryId?: number;
    companyName?: string;
    position?: string;
}

export function postFileForm(data: IFileFormData): Promise<any> {
    return axios.post(endpoints({ API_URL }).fileFormApplication, data, {
        withCredentials: true,
    });
}
